import { Injectable } from "@angular/core";
import {
  AlertController,
  IonNav,
  NavController,
  Platform,
} from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import {
  promptInput,
  promptResponse,
} from "src/app/components/ui/orwi-prompt/orwi-prompt.component";
import { OrwiPromptService } from "src/app/components/ui/orwi-prompt/orwi-prompt.service";
import { OrwiAddress } from "../dto/orwi-address";
import {
  IFeedBack,
  InvoiceAddress,
  IStoreFeedback,
  OrwiNotification,
  User,
} from "../dto/orwi-user";
import { UserEventsService } from "../events/user-events.service";
import { GlobalService } from "../global.service";
import { IdGeneratorService } from "../helpers/id-generator.service";
import { SocketService } from "../helpers/socket.service";
import { OrwiService } from "../orwi.service";

import { Plugins } from "@capacitor/core";
import { FbService } from "../events/fb.service";
import { BehaviorSubject } from "rxjs";

const { PushNotifications, SplashScreen } = Plugins;

@Injectable({
  providedIn: "root",
})
export class UserService {
  invoiceListMode: "select" | "list" = "list";
  invoiceAddresses$ = new BehaviorSubject<InvoiceAddress[]>([]);
  selectedInvoiceAdress: InvoiceAddress;

  userStatus: "virtual" | "new" | "registered";
  userGsm;
  userRegion = "tr";
  userLanguage = "tr";
  token = "";
  user: User;
  unreadNotificationCount = 0;

  afterLoginFunction: Function;

  constructor(
    private alertCtrl: AlertController,
    private plt: Platform,
    private glb: GlobalService,
    private os: OrwiService,
    private idGen: IdGeneratorService,
    private nav: NavController,
    private transloco: TranslocoService,
    private oPrompt: OrwiPromptService,
    private orwiSocket: SocketService,
    private ue: UserEventsService,
    private fb: FbService
  ) {
    ue.userEvents.subscribe((o) => {
      if (o == "logged-in") {
        this.orwiSocket.initEvents(this.token);
        this.getUnreadNotificationCount();
        // this.setPushToken();
        setTimeout(() => {
          this.orwiSocket.socket.connect();
        }, 100);
      }
    });
  }

  setPushToken(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.os
        .serviceRequest(
          "/api/device/register",
          {
            pushToken: this.glb.PushToken,
            deviceInfo: this.glb.DeviceInfo,
            userLanguage: this.userLanguage,
            userRegion: this.userRegion,
            uuid: this.glb.DeviceInfo?.uuid,
            appName : "orwi"           
          },
          this.token
        )
        .toPromise()
        .then((o: any) => {
          let result = Object.assign(o.response);

          if (result.newVersion) {
            this.newVersion();
          }
          resolve(true);
        });
    });
  }

  requestOtp(gsm): Promise<"ok" | "new"> {
    return new Promise((resolve, reject) => {
      //requestOtp, param: gsm
      //if valid gsm return 'ok' else if new user return 'new'
      this.os
        .serviceRequestPromise("/api/user/requestOtp", {
          gsm: gsm,
          source: this.plt.is("capacitor") ? "orwi" : "web",
        })
        .then((o: any) => {
          if (o.response) {
            let result = Object.assign(o.response);
            this.glb.consolelog(result);
            this.userStatus = result.message == "ok" ? "registered" : "new";
            resolve(result.message);
          }

          if (o.error) {
            this.glb.toast(o.error.desc, "", "middle", "warning");
            this.savelog("⁉️" + o.error.desc);
            this.glb.closeLoading();
          }
        });
    });
  }

  verifyOtp(_gsm, _otp): Promise<User> {
    //verifyOtp, param: gsm, otp
    //if success with registered user return user object, token and flag {user: user, token: token, flag: registered}
    //if success with new user return blank user object, token and flag {user: new user, token: token, flag: new}
    //if user not found, return error with "user not found"
    //if otp incorrect, return error with "otp invalid"
    //send otp to server, is ok and registered user, return user, new user retur user with new flag else error
    return new Promise((resolve, reject) => {
      this.os
        .serviceRequestPromise("/api/user/verifyOtp", { gsm: _gsm, otp: _otp })
        .then(
          (o: any) => {
            if (o.response) {
              let result = Object.assign(o.response);
              this.user = Object.assign({}, new User(), result.userInfo);
              this.token = result.token;
              if (!this.user) {
                this.user = new User();
                this.user.gsm = _gsm;
                this.user.id = this.idGen.generate();
              }
              let _userInfo = { gsm: _gsm, token: this.token };
              localStorage.setItem("userInfo", JSON.stringify(_userInfo));
              this.ue.userEvents.next("logged-in");
              resolve(this.user);
            } else if (o.error) {
              reject(o.error.code);
            }
          },
          (e) => {
            reject(e);
          }
        );
    });
  }

  autoLogin(): Promise<undefined | User> {
    //autoLogin, param: token
    //if success return user object and token
    //if token invalid, return error with "token invalid"
    return new Promise((resolve, reject) => {
      //get saved data
      let _userInfo = JSON.parse(localStorage.getItem("userInfo"));

      if (_userInfo && !_userInfo.token) _userInfo = null;

      if (_userInfo == null) {
        this.os.serviceRequestPromise("/api/user/createVirtualUser", {}).then(
          (o: any) => {
            if (o.response) {
              this.glb.consolelog(o.response);
              let result = Object.assign(o.response);
              this.user = Object.assign({}, new User(), result.userInfo);
              //this.openCheckIn = result.userInfo.openCheckIn
              this.token = result.token;
              //this.savelog(`Auto Login`)
              let _userInfo = { gsm: this.user.gsm, token: this.token };
              localStorage.setItem("userInfo", JSON.stringify(_userInfo));
              //this.oe.userEvents.next("logged-in")
              //this.ue.userEvents.next("logged-in")
              resolve(result.userInfo);
            }

            if (o.error) {
              this.glb.consolelog(o.error);
            }
          },
          (e) => {
            this.glb.consolelog("auto login error", e);
            resolve(undefined);
          }
        );
      } else {
        this.os
          .serviceRequestPromise("/api/user/autoLogin", {
            token: _userInfo.token,
          })
          .then(
            (o: any) => {
              if (o.response) {

                this.glb.consolelog(o.response);
                let result = Object.assign(o.response);
                this.user = Object.assign({}, new User(), result.userInfo);
                //this.openCheckIn = result.userInfo.openCheckIn
                this.token = result.token;
                //this.savelog(`Auto Login`)
                let _userInfo = { gsm: this.user.gsm, token: this.token };
                localStorage.setItem("userInfo", JSON.stringify(_userInfo));
                //this.oe.userEvents.next("logged-in")
                this.userStatus = this.user.isVirtual
                  ? "virtual"
                  : "registered";
                if (this.userStatus == "registered") {
                  this.ue.userEvents.next("logged-in");
                }
                this.fb.login(this.user.id, "auto_login");
                resolve(result.userInfo);
              } else if (o.error) {
                this.glb.consolelog("AutoLogin Error", o.error);
                localStorage.removeItem("userInfo");
                resolve(undefined);
              }
            },
            (e) => {
              localStorage.removeItem("userInfo");
              this.glb.consolelog("auto login error", e);
              resolve(undefined);
            }
          );
      }
    });
  }

  deleteAccount(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.os
        .serviceRequest("/api/user/deleteUser", { userId: this.user.id })
        .toPromise()
        .then((o: any) => {
          let result = Object.assign(o.response);
          resolve(result);
        });
    });
  }

  saveUser(): Promise<any> {
    return this.os
      .serviceRequest("/api/user/saveUser", this.user, this.token)
      .toPromise();
  }

  savelog(message) {
    let platform = "🕸️";
    if (this.plt.is("capacitor")) {
      platform = "📱";

      if (this.plt.is("android")) {
        platform += "A ";
      }

      if (this.plt.is("ios")) {
        platform += "I ";
      }
    }
    this.os
      .serviceRequestPromise(
        "/api/user/saveLog",
        { message: platform + message },
        this.token
      )
      .then((o) => {
        this.glb.consolelog(o);
      });
  }

  firstName() {
    if (this.user.name) {
      if (this.user.name.split(" ")[0]) {
        return this.user.name.split(" ")[0];
      } else {
        return this.user.name;
      }
    } else {
      return this.user.id;
    }
  }

  logout() {
    //verifyOtp, param: token
    //server dispose the token, set user flag loggedout
    localStorage.setItem("userInfo", null);
    this.user = undefined;
    this.ue.userEvents.next("logged-out");

    // this.oe.userEvents.next("logged-out")
    this.autoLogin().then((o) => {
      this.nav.navigateRoot("home");
    });
  }

  requestQrCode(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.os
        .serviceRequest("/api/user/requestQrCode", { width: 256 }, this.token)
        .toPromise()
        .then((o: any) => {
          let result = Object.assign(o.response);
          resolve(result);
        });
    });
  }

  getAddressList(): Promise<OrwiAddress[]> {
    return new Promise((resolve, reject) => {
      this.os
        .serviceRequest("/api/address/getAddresses", {}, this.token)
        .toPromise()
        .then((o: any) => {
          let result = Object.assign(o.response);
          resolve(result);
        });
    });
  }

  saveAddress(address: OrwiAddress): Promise<OrwiAddress> {
    return new Promise((resolve, reject) => {
      this.os
        .serviceRequest("/api/address/saveAddress", address, this.token)
        .toPromise()
        .then((o: any) => {
          let result = Object.assign(o.response);
          resolve(result);
        });
    });
  }

  deleteAddress(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.os
        .serviceRequest("/api/address/deleteAddress", { id: id }, this.token)
        .toPromise()
        .then((o: any) => {
          let result = Object.assign(o.response);
          resolve(result);
        });
    });
  }

  runAfterLogin() {
    this.afterLoginFunction();
    this.afterLoginFunction = undefined;
  }

  getPoints() {
    return new Promise((resolve, reject) => {
      this.os
        .serviceRequestPromise("/api/user-point/getPoints", {}, this.token)
        .then(
          (o: any) => {
            if (o.response) {
              this.user.orwiPoints = o.response;
              //this.initPoints()
              resolve(true);
            }
          },
          (e) => {
            reject(false);
          }
        );
    });
  }

  getStorePoint(id, parentId) {
    let point =
      this.user?.orwiPoints.find(
        (o) => o.pointType == "store" && o.storeId == id
      )?.balance || 0;
    if (point == 0) {
      point =
        this.user?.orwiPoints.find(
          (o) => o.pointType == "store" && o.storeId == parentId
        )?.balance || 0;
    }
    return point;
  }

  /*   initPoints() {
      if (this.glb.activeStore) {
        this.stampPoint = this.user.orwiPoints.find(o => o.storeId == this.glb.activeStore.id && o.pointType == "stamp") || new PointInfo()
        this.glb.consolelog("initPorints", this.stampPoint)
      }
    } */

  checkActiveUser(afterLoginFunction: Function = undefined): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.afterLoginFunction = afterLoginFunction;
      if (!this.user || this.user.isVirtual) {
        let message = this.transloco.translate(
          "For this action, membership required, are you want login or register?"
        );
        let title = this.transloco.translate("Login/Register");
        let inp: promptInput = {
          id: "name",
          type: "text",
          placeholder: "Adınızı Girin",
          required: true,
        };
        let inp2: promptInput = {
          id: "surname",
          type: "text",
          placeholder: "soyadınızı Girin",
          required: true,
        };
        let inps: promptInput[] = [inp, inp2];

        let prompt_response = this.oPrompt.showComponent({
          title: title,
          message: message,
        });

        prompt_response.click.subscribe((response: promptResponse) => {
          if (response) {
            setTimeout(() => {
              if (response.button.id == "ok") {
                this.nav.navigateForward("otp-request");
              }
            }, 600);
          }

          prompt_response.closeClick.emit();
          resolve(false);
        });
      } else {
        resolve(true);
        if (this.afterLoginFunction) this.afterLoginFunction(true);
      }
    });
  }

  checkActiveUserWO(
    afterLoginFunction: Function = undefined
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.afterLoginFunction = afterLoginFunction;
      if (!this.user || this.user.isVirtual) {
        this.nav.navigateForward("otp-request");
        resolve(false);
      } else {
        resolve(true);
        if (this.afterLoginFunction) this.afterLoginFunction(true);
      }
    });
  }

  saveFeedBack(feedBack: IFeedBack) {
    return this.os.serviceRequestPromise(
      "/api/helper/saveFeedback",
      feedBack,
      this.token
    );
  }

  saveStoreFeedback(feedback: IStoreFeedback) {
    return this.os.serviceRequestPromise(
      "/api/rating/saveStoreFeedback",
      feedback,
      this.token
    );
  }

  getNotifitcations(skip, limit): Promise<OrwiNotification[]> {
    return new Promise((resolve, reject) => {
      console.log("skip,limit,notfication", { skip: skip, limit: limit });
      this.os
        .serviceRequestPromise(
          "/api/notification/getNotifications",
          { skip: skip, limit: limit },
          this.token
        )
        .then((o: any) => {
          if (o.response) {
            resolve(o.response);
          }
        });
    });
  }

  getUnreadNotificationCount(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.os
        .serviceRequest(
          "/api/notification/getUnreadNotificationCount",
          {},
          this.token
        )
        .subscribe((o: any) => {
          this.unreadNotificationCount = o.response.count;
          this.updateBadgeCount();
          resolve(this.unreadNotificationCount);
        });
    });
  }

  deleteNotification(id) {
    this.os
      .serviceRequest(
        "/api/notification/deleteNotification",
        { id: id },
        this.token
      )
      .subscribe((o) => {});
  }

  async notifyPermissions() {
    let that = this;
    const alert = await this.alertCtrl.create({
      header: this.transloco.translate("Notification Permisions"),
      inputs: [
        {
          name: "sms",
          type: "checkbox",
          label: this.transloco.translate("SMS Notifications"),
          value: "sms",
          checked: that.user.permissions.sms,
        },

        {
          name: "email",
          type: "checkbox",
          label: this.transloco.translate("Email Notifications"),
          value: "email",
          checked: that.user.permissions.email,
        },

        {
          name: "phone",
          type: "checkbox",
          label: this.transloco.translate("Phone Calls"),
          value: "phone",
          checked: that.user.permissions.phone,
        },
      ],
      buttons: [
        {
          text: this.transloco.translate("Cancel"),
          role: "cancel",
          cssClass: "secondary",
          handler: () => {},
        },
        {
          text: this.transloco.translate("Ok"),
          handler: (e) => {
            that.user.permissions.push =
              e.find((p) => p == "push") != undefined;
            that.user.permissions.sms = e.find((p) => p == "sms") != undefined;
            that.user.permissions.email =
              e.find((p) => p == "email") != undefined;
            that.user.permissions.phone =
              e.find((p) => p == "phone") != undefined;
          },
        },
      ],
    });

    await alert.present();
  }

  async updateBadgeCount() {
    if (!this.plt.is("capacitor")) return;
    PushNotifications.removeAllDeliveredNotifications();
  }

  newVersion(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      SplashScreen.hide();

      //Orwi'nin yeni sürümü yayında, devam etmek için indirmelisin.
      let message = this.transloco.translate(
        "The new version of Orwi is live, you have to download it to continue."
      );
      let title = this.transloco.translate("Just Released");

      let prompt_response = this.oPrompt.showComponent({
        closable: false,
        title: title,
        message: message,
        buttons: [{ text: this.transloco.translate('Download'), id: "ok", color: "primary" }],
      });

      prompt_response.click.subscribe((response: promptResponse) => {
        if (response) {
          if (this.plt.is("android")) {
            document.location.href =
              "https://play.google.com/store/apps/details?id=app.orwi.orwi";
          } else if (this.plt.is("ios")) {
            document.location.href =
              "https://apps.apple.com/us/app/orwi/id1505302046?ls=1";
          } else {
          }
        }

        //prompt_response.closeClick.emit()
        resolve(false);
      });
    });
  }

  getInvoiceAddressList() {
    this.savelog("Get Invoice Addresses");
    return new Promise((resolve, reject) => {
      this.os
        .serviceRequestPromise("/api/address/getInvoiceAddress", {}, this.token)
        .then((o: any) => {
          this.invoiceAddresses$.next(o.response);
          resolve(o.response);
        });
    });
  }

  saveInvoiceAddress(item: InvoiceAddress) {
    this.savelog("Save Invoice Address");
    this.os
      .serviceRequestPromise(
        "/api/address/saveInvoiceAddress",
        item,
        this.token
      )
      .then((o: any) => {
        this.getInvoiceAddressList();
      });
  }

  deleteInvoiceAddress(id) {
    this.savelog("Delete Invoice Address");
    return new Promise((resolve, reject) => {
      this.os
        .serviceRequestPromise(
          "/api/address/deleteInvoiceAddress",
          { id: id },
          this.token
        )
        .then((o: any) => {
          this.getInvoiceAddressList().then((o) => {
            resolve(o);
          });
        });
    });
  }
}
