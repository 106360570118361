import { ChangeDetectorRef, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { arrayUpdate } from "@datorama/akita";
import { NavController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { ScratchService } from "src/app/components/gamification/orwi-scratch/state/scratch.service";
import { ScratchStore } from "src/app/components/gamification/orwi-scratch/state/scratch.store";
import { OrwiPromptService } from "src/app/components/ui/orwi-prompt/orwi-prompt.service";
import { MenuStore } from "src/app/state/menu.store";
import { FolioRow } from "../dto/orwi-folio";
import { Coupon } from "../dto/orwi-store";
import { FolioService } from "../folio/folio.service";
import { GlobalService } from "../global.service";
import { CouponService } from "../store/coupon.service";
import { StoreService } from "../store/store.service";
import { UserService } from "../user/user.service";
import { AppService } from "../utils/app.service";
import { SocketService } from "./socket.service";
import { routes } from "src/app/app-routing.module";

@Injectable({
  providedIn: "root",
})
export class SoketPushDeeplinkRoutingService {
  //TODO : ilk loginde sorun olabilir...

  constructor(
    private us: UserService,
    private sc: ScratchService,
    private cs: CouponService,
    private fs: FolioService,
    private ss: StoreService,
    private nav: NavController,
    private as: AppService,
    private op: OrwiPromptService,
    private os: SocketService,
    private transloco: TranslocoService,
    private router: Router,
    private menuStore: MenuStore,
    private glb: GlobalService
  ) {
    this.as.stateChanged.subscribe((o) => {
      this.appState(o);
    });

    this.as.deeplinkReceived.subscribe((o) => {
      this.deepLink(o);
    });

    this.os.socket.fromEvent("folio-changed").subscribe((o) => {
      this.route({ type: "folio-changed", socketData: o });
    });

    this.os.socket.fromEvent("folio-closed").subscribe((o) => {
      this.route({ type: "folio-closed", socketData: o });
    });

    this.os.socket.fromEvent("folio-received").subscribe((o) => {
      this.route({ type: "folio-received", socketData: o });
    });

    this.os.socket.fromEvent("user-points-changed").subscribe((o) => {
      this.route({ type: "user-points-changed", socketData: o });
    });

    this.os.socket.fromEvent("store-menu-changed").subscribe((o) => {
      this.route({ type: "store-menu-changed", socketData: o });
    });

    this.os.socket.fromEvent("new-user-message").subscribe((o) => {
      this.route({ type: "new-user-message", socketData: o });
    });
  }

  async deepLink(url: string) {
    if (url.indexOf("in-store-home") >= 0) {
      let storeId = "";
      let createdURL = new URL(url);
      if (createdURL.searchParams.get("storeId") !== null) {
        storeId = createdURL.searchParams.get("storeId");
      }
      const user = await this.us.autoLogin();
      if (user) {
        const response = await this.ss.getStoreQr(storeId);
        if (response.link) {
          storeId = response.link;
          this.ss.storeCheckIn(storeId);
          return;
        }
      }
    }
    if (
      url.indexOf("orwi.app") > 0 &&
      !routes
        .filter((el) => el.path.length > 0)
        .filter((el) => el.path !== "in-store-home")
        .some((el) => url.split("orwi.app/")[1].includes(el.path))
    ) {
      let formattedUrl = url;
      console.log("");

      this.us.savelog("Deeplink -> " + formattedUrl);
      if (formattedUrl.includes("/token/")) {
        // Burada tokeni alıp autologin yapacağız.
        this.initTokenAndStore(formattedUrl);
      } else {
        if (this.as.homePageLoaded) {
          const user = await this.us.autoLogin();
          if (user && user.openCheckIn) {
            let title = this.transloco.translate("Open Check");
            let message = this.transloco.translate(
              "You have an open ticket, redirecting to store."
            );
            let btn: any[] = [
              {
                id: "ok",
                text: this.transloco.translate("Ok"),
                color: "primary",
              },
            ];

            let opr = this.op.showComponent({
              title: title,
              message: message,
              buttons: btn,
              closable: false,
            });
            opr.click.subscribe((o: any) => {
              if (o.button.id == "ok") {
                this.ss.storeCheckIn(this.us.user.openCheckIn);
              } else if ((o.button.id = "delete")) {
              }
              opr.closeClick.emit();
            });

            return;
          }
          this.ss.storeCheckIn(formattedUrl);
        } else {
          let ps = formattedUrl.lastIndexOf("/");
          this.as.deeplinkQrCode = formattedUrl;
        }
      }
    }
  }

  async initTokenAndStore(url: string) {
    let token = url.split("/token/")?.[1]?.split("/")?.[0];
    let isUserLogged = localStorage.getItem("userInfo");
    let storeId = url.split("/token/")?.[0];
    if (token) {
      localStorage.setItem(
        "userInfo",
        JSON.stringify({
          token,
        })
      );

      this.us.autoLogin().then((user) => {
        if (user && user?.openCheckIn) {
          let title = this.transloco.translate("Open Check");
          let message = this.transloco.translate(
            "You have an open ticket, redirecting to store."
          );
          let btn: any[] = [
            {
              id: "ok",
              text: this.transloco.translate("Ok"),
              color: "primary",
            },
          ];

          let opr = this.op.showComponent({
            title: title,
            message: message,
            buttons: btn,
            closable: false,
          });
          opr.click.subscribe((o: any) => {
            if (o.button.id == "ok") {
              this.ss.storeCheckIn(this.us.user.openCheckIn);
            } else if ((o.button.id = "delete")) {
            }
            opr.closeClick.emit();
          });

          return;
        } else {
          this.ss.storeCheckIn(storeId);
        }
      });
    } else {
      this.ss.storeCheckIn(storeId);
    }
  }

  async appState(isActive) {
    if (isActive) {
      if (this.fs.folio?.id && this.fs.folio?.id.length > 0)
        await this.fs._refreshFolio();
      if (this.us.user && !this.us.user.isVirtual) await this.us.getPoints();
      if (this.us.user && !this.us.user.isVirtual)
        await this.cs.getUserCoupons();
    }
  }

  route(param: orwiRouteParam) {
    if (param.type == "folio-changed") this.applyFolioChanged(param);
    if (param.type == "folio-closed") this.applyFolioClosed();
    if (param.type == "folio-received") this.applyFolioReceived(param);
    if (param.type == "store-menu-changed") this.applyStoreMenuChanged(param);
    if (param.type == "user-points-changed") this.applyUserPointsChanged();
    if (param.type == "new-user-message") this.newUserMessage(param);
    if (param.type == "new-scratch") this.applyScratch(param);
  }

  newUserMessage(param) {
    if (this.router.url == "") {
    }
  }
  isClosedModalOpened = false;
  async applyFolioClosed() {
    if (this.isClosedModalOpened) return;
    await this.fs._getFolio(false);
    this.us.user.openCheckIn = "";
    let title = this.transloco.translate("Check Closed");
    let desc = this.transloco.translate("Check closed by the store.");
    let opr = this.op.showComponent({
      title: title,
      message: desc,
      buttons: [],
      closable: true,
      id: "folio-closed-modal",
    });
    this.isClosedModalOpened = true;
    opr.click.subscribe((o) => {
      this.nav.navigateRoot("home");
      this.isClosedModalOpened = false;
    });
  }

  async applyFolioReceived(param: orwiRouteParam) {
    if (param.socketData) {
      this.fs.folio = await this.fs._getFolioById(param.socketData);
      await this.ss.fetchStoreInfo(this.fs.folio.storeId);
      this.nav.navigateForward("card-payment");
    } else if (param.pushData) {
      this.fs.folio = await this.fs._getFolioById(param.pushData);
      await this.ss.fetchStoreInfo(this.fs.folio.storeId);
      this.nav.navigateForward("card-payment");
    }
  }

  //pushda adisyona git
  applyFolioChanged(param: orwiRouteParam) {
    if (param.socketData) {
      let rows: FolioRow[] = param.socketData;
      rows.forEach((rw) => {
        let fl = this.fs.folio.rows.findIndex((fl) => fl.id == rw.id);
        if (fl > -1) {
          this.fs.folio.rows[fl] = rw;
        } else {
          this.fs.folio.rows.push(rw);
        }
      });
    } else if (param.pushData) {
      this.fs._getFolio();
    }
  }

  applyUserPointsChanged() {
    this.us.getPoints();
    this.cs.getUserCoupons();
  }

  applyStoreMenuChanged(param: orwiRouteParam) {
    if (param.socketData) {
      let p = param.socketData.product;
      let pg = param.socketData.productGroup;

      if (p) {
        let i = this.ss.store.products.findIndex((xx) => xx.id == p.id);
        this.ss.store.products[i] = p;
        this.menuStore.update(({ products }) => ({
          products: arrayUpdate(products, p.id, p, "id"),
        }));
      }

      if (pg) {
        let i = this.ss.store.productGroups.findIndex((xx) => xx.id == pg.id);
        this.ss.store.productGroups[i] = pg;
        this.menuStore.update(({ groups }) => ({
          groups: arrayUpdate(groups, pg.id, pg, "id"),
        }));

        if (this.menuStore.getValue().selectedGroup?.id == pg.id) {
          this.menuStore.update({ selectedGroup: pg });
        }

        if (this.menuStore.getValue().selectedSubGroup?.id == pg.id) {
          this.menuStore.update({ selectedSubGroup: pg });
        }
      }
    }
  }

  applyScratch(param: orwiRouteParam) {
    //this.nav.navigateForward(["my-gifts", { param }])

    this.cs.getUserCoupons().then((cp: Coupon[]) => {
      let cps = cp.find((c) => c.id == param.pushData);

      this.sc.init(cps);
    });
  }
}

//folio-received - kerzz tarafından oluşturalın adisyonun kullanıcıya gönderilmesi.
//hem push & hem socket olmalı
//parametre  push:data.orwi.folioId // socket:[foioId]

//folio-closed - adisyon kerzz tarafında kapandığında yada iptal olduğunda oluşuyor
//hem push & hem socket olmalı
//parametre  push:data.orwi.folioId // socket:[foioId]

//folio-changed - adisyon kerzz'den değiştirilirse oluşuyor
//hem push & hem socket olmalı
//parametre  push:data.orwi.folioId // socket:data:FolioRows (changed)

//user-points-changed - herhangi bir şekilde puan değişirse oluşuyor
//sadece socket
//paremetre socket: points
//user refresj et

//store-menu-changed o storeda değişlik varsa
//sadece socket
//paremetre socket: menu

//push folio-received | folio-closed | folio-changed | user-points-changed

//user-points-changed
//folio-changed
//store-menu-changed
//folio-closed
//folio-received

export interface orwiRouteParam {
  type: routeType;
  socketData?: any;
  pushData?: any;
}

export type routeType =
  | "folio-received"
  | "folio-changed"
  | "folio-closed"
  | "user-points-changed"
  | "store-menu-changed"
  | "new-user-message"
  | "new-scratch"
  | "redirect-url";
