import { Component } from "@angular/core";

import {
  MenuController,
  ModalController,
  NavController,
  Platform,
} from "@ionic/angular";
//import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { UserService } from "./services/user/user.service";

import { Keyboard, KeyboardResize } from "@capacitor/keyboard";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { GlobalService } from "./services/global.service";
import { PushService } from "./services/helpers/push.service";
import { OrwiService } from "./services/orwi.service";
import { environment } from "../environments/environment";
import { UIStore } from "./state/ui/ui.store";
import { LocationStrategy } from "@angular/common";
import { MasterpassService } from "./payment/services/masterpass.service";
import { FbService } from "./services/events/fb.service";

import { SocialSharing } from "@awesome-cordova-plugins/social-sharing/ngx";
import { TranslocoService } from "@ngneat/transloco";
import { Device } from "@capacitor/device";
import { TextMainPage } from "./pages/text-main/text-main.page";
import { register } from "swiper/element/bundle";
import {
  promptButton,
  promptResponse,
} from "./components/ui/orwi-prompt/orwi-prompt.component";
import { StoreService } from "./services/store/store.service";
import { OrwiPromptService } from "./components/ui/orwi-prompt/orwi-prompt.service";
import { FeedService } from "./services/home/feed.service";
import { routes } from "./app-routing.module";
import { App } from "@capacitor/app";

register();
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  theme = "auto";
  menus = [];
  download = false;

  langs = this.transloco.getAvailableLangs() as string[];

  appVersion;
  constructor(
    private uiStore: UIStore,
    private os: OrwiService,
    private router: Router,
    private platform: Platform,
    //private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public us: UserService,
    private modalCtrl: ModalController,
    private nav: NavController,
    private glb: GlobalService,
    private push: PushService,
    private ms: MasterpassService,
    private locationStrategy: LocationStrategy,
    private fb: FbService,
    private socialSharing: SocialSharing,
    private menu: MenuController,
    public transloco: TranslocoService,
    private ss: StoreService,
    private op: OrwiPromptService,
    private feedService: FeedService
  ) {
    this.menu.swipeGesture(false, "first");

    transloco.langChanges$.subscribe((res) => {
      this.feedService.getCatagories();
    });

    this.appVersion = environment.appVersion;
    let download = false;
    if (!platform.is("capacitor")) {
      this.router.events.subscribe(async (event) => {
        if (event instanceof NavigationStart) {
          let url = event.url;
          console.log(
            url,
            ((!routes
              .filter((el) => el.path.length > 0)
              .some((el) => url.split("/")[1].includes(el.path)) &&
            url.indexOf("check-in") < 0) || url.includes('in-store-home'))  &&
            !url.includes("survey")
            ,
            routes
              .filter((el) => el.path.length > 0)
              .filter(el => el.path !== 'in-store-home')
          );
          let storeId = null;
          if (
            url !== '/' &&
            ((!routes
              .filter((el) => el.path.length > 0)
              .some((el) => url.split("/")[1].includes(el.path)) &&
            url.indexOf("check-in") < 0) || url.includes('in-store-home'))  &&
            !url.includes("survey")
          ) {
            let newUrl = new URL(location.href);

            storeId = newUrl.pathname.split("/")?.[1];
            if (url.startsWith('/in-store-home')) {
              storeId = url.split('storeId=')?.[1];
              const user = await this.us.autoLogin();
              if (user) {
                const response = await this.ss.getStoreQr(storeId);
                if (response.link) {
                  storeId = response.link;
                }
              }
            }

            this.glb.startupQrCode = storeId;
            console.log("burada");

            return;
          }
          if (!router.navigated) {
            if (url.indexOf("check-in") > -1) {
              const user = await this.us.autoLogin();
              if (user && user?.openCheckIn) {
                let title = this.transloco.translate("Open Check");
                let message = this.transloco.translate(
                  "You have an open ticket, redirecting to store."
                );
                let btn: promptButton[] = [
                  {
                    id: "ok",
                    text: this.transloco.translate("Ok"),
                    color: "primary",
                  },
                ];

                let opr = this.op.showComponent({
                  title: title,
                  message: message,
                  buttons: btn,
                  closable: false,
                });
                opr.click.subscribe((o: promptResponse) => {
                  if (o.button.id == "ok") {
                    this.ss.storeCheckIn(this.us.user.openCheckIn);
                  } else if ((o.button.id = "delete")) {
                  }
                  opr.closeClick.emit();
                });

                return;
              }
              if (url.indexOf("/token/") > -1) {
                let code = url.split("/token/")?.[0];
                let token = url.split("/token/")?.[1]?.split("/")?.[0];
                localStorage.setItem(
                  "userInfo",
                  JSON.stringify({
                    token,
                  })
                );
                this.glb.startupQrCode = code;
                this.us.autoLogin().then((res) => { });
              } else {
                let ps = url.lastIndexOf("/");
                let code = url.substr(ps + 1, url.length);
                this.glb.startupQrCode = code;
              }
            }
            if (url.indexOf("/d/") > -1) {
              this.download = true;
              setTimeout(() => {
                this.nav.navigateForward(["redirect", { url: url }]);
              }, 1000);
            }
          }
        }

        if (event instanceof NavigationEnd) {
          console.log("NavigationEnd", event);
        }
      });
    }

    this.initializeApp();
  }

  async initializeApp() {
    await this.setUserLanguage();
    this.platform.ready().then(async () => {
      this.fb.init();

      //Initialize Dark Mode
      try {
        let systemDark = window.matchMedia("(prefers-color-scheme: dark)");
        systemDark.addEventListener("change", (d) => {
          this.colorTest(d.matches);
          this.glb.darkTheme = d.matches;
        });
      } catch (error) { }

      setTimeout(() => {
        this.theme = localStorage.getItem("theme") || "auto";
        this.setTheme();
      }, 1000);

      this.glb.DeviceInfo = await Device.getInfo();
      this.glb.DeviceInfo.uuid = (await Device.getId()).identifier;
      App.getInfo().then(res => {
        this.glb.DeviceInfo.appVersion = res.version
        this.glb.DeviceInfo.appBuild = res.build
        this.glb.DeviceInfo.appName = res.name
      })
      let token = await this.push.initPush();
      this.glb.PushToken = token;
      // console.log('deviceInfo', this.glb.DeviceInfo.uuid);

      if (this.platform.is("capacitor")) {
        Keyboard.setAccessoryBarVisible({ isVisible: true });
        Keyboard.setResizeMode({ mode: KeyboardResize.Native });
      }

      this.statusBar.styleDefault();
      //this.splashScreen.hide();
      if (!this.us.user && !this.download) {
        console.log("redirecting....", location.href);
        if (location.href.includes('/survey/')) {
          this.nav.navigateRoot(location.pathname);
        } else {
          this.nav.navigateRoot("welcome");
        }
      }
    });
  }

  logout() {
    this.us.logout();
  }

  onClick(event) {
    document.body.classList.toggle("dark", event.detail.checked);
  }

  colorTest(systemInitiatedDark) {
    this.glb.consolelog(systemInitiatedDark);
    document.body.classList.toggle("dark", systemInitiatedDark);
    this.glb.darkTheme = systemInitiatedDark;
  }

  themeChanged(e) {
    this.theme = e.detail.value;
    this.setTheme();
  }

  setTheme() {
    if (this.theme == "auto") {
      let systemDark = window.matchMedia("(prefers-color-scheme: dark)");
      if (systemDark.matches) {
        document.body.classList.add("dark");
        this.glb.darkTheme = true;
      } else {
        document.body.classList.remove("dark");
        this.glb.darkTheme = false;
      }
    } else if (this.theme == "dark") {
      document.body.classList.add("dark");
      this.glb.darkTheme = true;
    } else {
      document.body.classList.remove("dark");
      this.glb.darkTheme = false;
    }
    localStorage.setItem("theme", this.theme);
  }

  menulink(item) {
    this.glb.orwiContent = item;
    this.nav.navigateForward("browser");
  }

  async openMoreModal() {
    // const modal = await this.modalCtrl.create({
    //   component: TextMainPage,
    // });
    // modal.present();

    // const { data, role } = await modal.onWillDismiss();
    this.nav.navigateForward("text-main");
  }

  langChange(e) {
    console.log(e);
    localStorage.setItem("activeLang", e.detail.value);
    this.transloco.setActiveLang(e.detail.value);
  }

  login() {
    this.us.afterLoginFunction = undefined;
    this.nav.navigateForward("otp-request");
  }

  writeUs() {
    this.uiStore.update({ orwiContactVisible: true });
  }

  preventBackButton() {
    this.platform.backButton.subscribeWithPriority(9999, () => {
      document.addEventListener(
        "backbutton",
        function (event) {
          event.preventDefault();
          event.stopPropagation();
          console.log("hello");
        },
        false
      );
    });
  }

  demo() {
    if (environment.appVersion.indexOf("dev")) {
      //this.nav.navigateForward("payment-result")
    }

    // var options = {
    //   message: 'share this', // not supported on some apps (Facebook, Instagram)
    //   subject: 'the subject', // fi. for email
    //   files: ['https://via.placeholder.com/400x250'], // an array of filenames either locally or remotely
    //   url: '',
    //   chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
    //   //appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
    //   iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
    // };
    // this.socialSharing.shareWithOptions(options)

    //   Contacts.getContacts().then(result => {

    //     for (const contact of result.contacts) {
    //         console.log(contact);
    //     }
    // });
  }

  async setUserLanguage() {
    debugger;
    var dlang = await Device.getLanguageCode();
    this.glb.consolelog("user-language", this.us.userLanguage, dlang);
    this.us.userLanguage = dlang.value.slice(0, 2);
    this.us.userRegion = dlang.value.slice(3, 2);

    //this.us.userLanguage = "nl"
    //this.us.userRegion = "nl"

    if (this.us.userRegion == "") {
      this.us.userRegion = this.us.userLanguage.toLocaleUpperCase();
    }

    if (
      !this.transloco
        .getAvailableLangs()
        .some((el) => this.us.userLanguage == el)
    ) {
      this.us.userLanguage = "tr";
      this.us.userRegion = "TR";
    }

    let lang = localStorage.getItem("activeLang") ?? this.us.userLanguage;
    console.log(lang);
    this.transloco.setActiveLang(lang);
    this.transloco.setDefaultLang(lang);

    this.os.userLanguage = this.us.userLanguage;
    this.os.userRegion = this.us.userRegion;
  }
}
