import { Injectable, inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GlobalService } from "./global.service";
import { TranslocoService } from "@ngneat/transloco";
import { Device } from "@capacitor/device";

@Injectable({
  providedIn: "root",
})
export class OrwiService {
   oriwServiceUrl = "https://gandalf.orwi.app";
 // oriwServiceUrl = "https://192.168.1.125"
  //oriwServiceUrl = 'https://service-test.orwi.app';
  apiKey = "YWxpLTEyMzQ1MTEyNDU2NTQzMg";
  token = "";
  userLanguage = "tr";
  userRegion = "TR";
  private http: HttpClient = inject(HttpClient);
  private glb: GlobalService = inject(GlobalService);
  private transloco: TranslocoService = inject(TranslocoService);

  constructor() {
    //this.oriwServiceUrl = "https://gandalf.orwi.app"
    //this.oriwServiceUrl = "https://127.0.0.1"
    this.setUserLanguage()
  }

  serviceRequest(path, postData, _token = "") {
    if (!this.glb.networkConnected) {
      this.glb.toast("Connection Error", "No Internet", "middle", "danger");
      return undefined;
    }
    this.token = _token;
    let url = `${this.oriwServiceUrl}${path}`;
    return this.http.post(url, postData, this.getHeaders());
  }

  serviceRequestPromise(path, postData, _token = "") {
    return new Promise((resolve, reject) => {
      if (!this.glb.networkConnected) {
        this.glb.toast("Connection Error", "No Internet", "middle", "danger");
        return undefined;
      }

      if (!navigator.onLine) {
        this.glb.toast("Connection Error", "No Internet", "middle", "danger");
        reject("offline");
        return;
      }

      this.token = _token;
      let url = `${this.oriwServiceUrl}${path}`;
      this.http.post(url, postData, this.getHeaders()).subscribe(
        (o) => {
          resolve(o);
        },
        (e) => {
          this.glb.consolelog("error", e);
          this.glb.closeLoading();
          if (e.status == 0) {
            this.glb.closeLoading();
            setTimeout(() => {
              this.glb.toast(
                "Opss!.",
                this.transloco.translate(
                  "There is a problem, try again after a while"
                ),
                "middle",
                "danger"
              );
            }, 500);
          } else {
            this.glb.closeLoading();
            setTimeout(() => {
              this.glb.toast("Opss!..", e.error.message, "middle", "danger");
            }, 500);
          }
          reject(e.error.message);
        }
      );
    });
  }

  async setUserLanguage() {
    const deviceLanguage = await Device.getLanguageCode();
    let newLanguage = "tr";
    let newRegion = "TR";
    if (
      !this.transloco
        .getAvailableLangs()
        .some((el) => deviceLanguage.value == el)
    ) {
      newLanguage = deviceLanguage.value.slice(0, 2);
      newRegion = deviceLanguage.value.slice(3, 2).toLocaleUpperCase();

      if(newRegion == "") {
        newRegion = newLanguage.toLocaleUpperCase();
      }
    }

    let lang = localStorage.getItem("activeLang") ?? newLanguage;
    this.transloco.setActiveLang(lang);
    this.transloco.setDefaultLang(lang);

    this.userLanguage = lang;
    this.userRegion = lang.toLocaleUpperCase();
  }

  getHeaders() {
    let headerOpt: any = {
      "content-Type": "application/json",
      accept: "application/json",
      apikey: this.apiKey,
      "accept-language":
        localStorage.getItem("activeLang") ?? this.userLanguage,
      region: this.userRegion,
    };
    if (this.token) {
      headerOpt = {
        ...headerOpt,
        token: this.token,
      };
    }
    let httpOptions = {
      headers: new HttpHeaders(headerOpt),
    };
    return httpOptions;
  }

  public getIPAddress() {
    return this.http.get("https://api.ipify.org/?format=json");
  }

  getIP() {
    this.getIPAddress().subscribe((res: any) => {
      this.glb.ipAddress = res.ip;
    });
  }
}
