import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OrwiAddress } from 'src/app/services/dto/orwi-address';
import { locationEvent } from 'src/app/services/dto/orwi-location';
import { LocationEventsService } from 'src/app/services/events/location-events.service';
import { GlobalService } from 'src/app/services/global.service';
import { ModalService } from 'src/app/services/modal.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'orwi-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnInit {


  @Input() orwiAddress: OrwiAddress = new OrwiAddress()
  @Output() cancelForm = new EventEmitter
  @Output() saveForm = new EventEmitter

  constructor(private glb: GlobalService, private us: UserService, private mc: ModalController, private le: LocationEventsService) { }

  ngOnInit() { }

  cancel() {
    //this.cancelForm.emit()
    //this.closeForm()
    this.mc.dismiss()
  }

  save() {
    this.validateForm()
  }

  closeForm() {

    let bd = document.getElementById("backdrop-address")
    bd.style.height = "0px"

    let el = document.getElementById("address-form")
    el.classList.add("animate__slideOutDown")
    setTimeout(() => {
      el.classList.remove("animate__slideOutDown")
    }, 1000);

    setTimeout(() => {
      el.style.position = ""
      el.style.zIndex = "200"
      el.style.top = "0px"
    }, 200);
  }

  validateForm() {
    let nameValid = this.orwiAddress.name.length > 1 ? true : false
    let directionValid = this.orwiAddress.direction == "" ? false : true
    if (!nameValid) {
      this.glb.shakeInput("address-name")
    }

    if (!directionValid) {
      this.glb.shakeInput("address-direction")
    }

    if (nameValid && directionValid) {
   

        this.us.saveAddress(this.orwiAddress).then(o => {
          this.le.addressSaved.next(o)
          this.glb.closeLoading()
          this.mc.dismiss()
        })
    
    }

  }

  


  
}
